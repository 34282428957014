@keyframes scrollFloating {
  0%   { transform: translate(-50%, -20px); }
  60%   { transform: translate(-50%, 0); }
  100%   { transform: translate(-50%, -20px); }
}

@media screen and (min-width: $screen-sm-min)  {
  .anime {
    visibility: hidden;
    &.anime-run {
      visibility: visible;
    }
  }
  .sect-title.anime-run,
  .sect-title2.anime-run {
    @include fadeInDown($duration: 0.5s, $delay: 0.1s);
  }
  .service-list {
    .item.anime-run {
      @include fadeIn($duration: 0.5s);
      @for $i from 1 through 12 {
        &:nth-child(#{$i}) {
          animation-delay: #{$i * 0.1 - 0.1}s;
        }
      }
    }
  }
  .recommend-list {
    .item.anime-run {
      &:nth-child(2n+1) {
        @include fadeInRight($duration: 0.5s);
      }
      &:nth-child(2n) {
        @include fadeInLeft($duration: 0.5s);
      }
      @for $i from 1 through 12 {
        &:nth-child(#{$i}) {
          animation-delay: #{$i * 0.2 - 0.1}s;
        }
      }
    }
  }
  .why-list {
    .item.anime-run {
      &:nth-child(2n+1) {
        @include fadeInRight($duration: 0.5s);
      }
      &:nth-child(2n) {
        @include fadeInLeft($duration: 0.5s);
      }
      @for $i from 1 through 12 {
        &:nth-child(#{$i}) {
          animation-delay: #{$i * 0.2 - 0.1}s;
        }
      }
    }
  }
  .compare-content.anime-run {
    @include fadeIn($duration: 0.5s, $delay: 0.2s);
  }
  .doctor-content.anime-run {
    @include fadeInRight($duration: 0.5s, $delay: 0.2s);
  }
  .contact-content.anime-run {
    @include fadeInUp($duration: 0.5s, $delay: 0.2s);
  }
  // .sect2-content-txt.anime-run {
  //   @include zoomIn($duration: 0.5s, $delay: 0.2s);
  // }
  // .sect6-content {
  //   .pic,
  //   .txt,
  //   .bt {
  //     @include fadeIn($duration: 0.5s);
  //   }
  //   .pic {
  //     animation-delay: 0.15s;
  //   }
  //   .txt {
  //     animation-delay: 0.3s;
  //   }
  //   .bt {
  //     animation-delay: 0.45s;
  //   }
  // }
}
