.floating {
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  .list.pc {
    display: none;
  }
  .list.m {
    display: table;
    width: 100%;
    table-layout: fixed;
    li {
      display: table-cell;
      vertical-align: middle;
      a {
        background: $brand-primary;
        color: #fff;
        @include fz(12px);
        line-height: 1.2;
        padding: 0.6em 0;
        .ic {
          font-size: 1.5em;
        }
        .txt {
          display: block;
          padding-top: 0.3em;
        }
      }
    }
  }
}


@media (min-width: $screen-sm-min) {
}
@media (min-width: $screen-md-min) {
  .floating {
    width: 55px;
    bottom: 28%;
    .list.m {
      display: none;
    }
    .list.pc {
      display: block;
      @include fz(13px);
      > li {
        + li {
          margin-top: 5px;
        }
        > a {
          color: #fff;
          background: $brand-primary;
          font-weight: bold;
          border-left: 4px solid $brand-primary2;
          line-height: 50px;
          span {
            display: inline-block;
            vertical-align: middle;
            line-height: (25 / 16);
            margin-bottom: 0.2em;
          }
          &:hover {
            background: $brand-primary2;
            border-left-color: $brand-primary;
          }
          .txt {
            display: none;
          }
        }
      }
    }
    &-contact {
      position: absolute;
      top: 0;
      right: -384 / 16 + 0em;
      background: $brand-primary2;
      color: #fff;
      width: 384 / 16 + 0em;
      overflow: hidden;
      transition: 0.3s;
      .toggle-floating-contact.active + & {
        right: 0;
      }
      .call {
        float: left;
        width: calc(100% - 55px);
        padding: (28 / 16 + 0em) 0 70px 0;
        position: relative;
        a:hover {
          color: $brand-primary3;
        }
        .title,
        .desc {
          @include fz(15,16);
          span {
            display: block;
            font-family: $font-family-sans-serif2;
            font-weight: 300;
          }
        }
        .title {
          font-weight: bold;
          margin-bottom: 1.6em;
          span {
            @include fz(20,15);
            &:after {
              content: '';
              display: block;
              width: 1.2em;
              height: 1px;
              background: $brand-primary3;
              margin: 0 auto 0.2em;
            }
          }
        }
        .desc {
          line-height: 1.4;
          span {
            @include fz(25,15);
          }
        }
        &:before {
          content: '';
          display: block;
          background: url(../img/floating_deco.png) no-repeat;
          background-size: contain;
          width: 185 * 0.75px;
          height: 56 * 0.75px;
          position: absolute;
          right: 0;
          bottom: 10px;
        }
      }
      .bt-group {
        width: 55px;
        float: right;
        background: $brand-primary;
        .bt {
          display: block;
          width: 100%;
          padding: 2em 0;
          font-family: $font-family-sans-serif2;
          font-weight: 300;
          @include fz(17,16);
          + .bt {
            border-top: 1px solid #fff;
          }
          .ic {
            font-size: 2em;
          }
          &:hover {
            background: lighten($brand-primary2,15%);
          }
          &.line:hover {
            background: $brand-line;
          }
        }
      }
    }
  }
}
@media (min-width: $screen-xl-min) {
  .floating {
    width: 106px;
    .list.pc {
      @include fz(16px);
      > li {
        > a {
          line-height: 83px;
          border-left-width: 8px;
        }
      }
    }
    &-contact {
      .call {
        width: calc(100% - 106px);
      }
      .bt-group {
        width: 106px;
        .bt {
          padding: 1.5em 0;
        }
      }
    }
  }
}
