.footer {
  background: $brand-primary4;
  color: #fff;
  @include fz(13px);
  text-align: center;
  padding: 3em 0;
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 8px;
    background: linear-gradient(to right, #9a373e 0%, #682e32 67.78%, #202020 89.95%, #202020 100%);
  }
  &-service,
  &-contact {
    margin-bottom: 2em;
  }
  &-title {
    color: $brand-primary;
    @include fz(20,14);
    font-weight: bold;
    margin: 0 0 1em;
    &:after {
      content: '';
      display: block;
      width: 1em;
      height: 2px;
      background: $brand-primary;
      transform: skewX(-30deg);
      margin: 0 auto;
    }
  }
  &-subtitle {
    color: $brand-primary;
    font-weight: bold;
  }
  &-desc {
    margin-bottom: 1em;
  }
  .siteinfo {
    li {
      a,
      > * {
        display: inline;
      }
    }
  }
  .follow {
    .ic {
      font-size: 1.2em;
      vertical-align: middle;
    }
    a {
      width: 30px;
      height: 30px;
      line-height: 30px;
      &:hover {
        text-decoration: none;
      }
    }
    a.sitemap {
      width: auto;
      padding: 0 0.5em;
      &:hover {
        color: $brand-primary;
      }
    }
    a.facebook {
      &:hover {
        color: $brand-facebook;
      }
    }
    a.twitter {
      &:hover {
        color: $brand-twitter;
      }
    }
    a.pinterest {
      &:hover {
        color: $brand-pinterest;
      }
    }
    a.linkedin {
      &:hover {
        color: $brand-linkedin;
      }
    }
    a.youtube {
      &:hover {
        color: $brand-youtube;
      }
    }
  }
  .copyright {
    line-height: 1.4;
    margin: 1em 0 0;
  }
}

@media (min-width: $screen-sm-min) {
  .footer {
    text-align: left;
    a {
      &:hover {
        color: $brand-primary;
        text-decoration: underline;
      }
    }
    &-top {
      @include clearfix;
    }
    &-service {
      float: left;
      width: calc(100% - 350px);
      max-width: 700px;
    }
    &-contact {
      float: right;
      width: 300px;
    }
    &-title {
      &:after {
        margin-left: 0;
      }
    }
    .siteinfo {
      li {
        > * {
          display: block;
        }
        .title {
          float: left;
          margin-right: 0.5em;
        }
        .desc {
          overflow: hidden;
        }
      }
    }
    &-bottom {
      @include clearfix;
      .follow {
        float: right;
        width: 300px;
        a {
          text-align: center;
        }
      }
      .copyright {
        margin: 0;
        padding: 0.5em 0;
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .footer {
    padding: 3.5em 0 4em;
    &-service {
      .row {
        @include clearfix;
        margin: 0 -22px;
        .col {
          float: left;
          width: 50%;
          padding: 0 22px;
        }
      }
    }
  }
}
@media (min-width: $screen-lg-min) {
  .footer {
    @include fz(14px);
  }
}
