.bt {
  @include btn-reset;
  &-normal {
    color: #fff;
    font-family: $font-family-sans-serif2;
    font-weight: 300;
    border: 1px solid #fff;
    padding: 0.5em 2em;
    a:hover &,
    &:hover {
      background: $brand-primary;
      border-color: $brand-primary;
    }
  }
}

@media (min-width: $screen-sm-min) {
}
@media (min-width: $screen-md-min) {
}
@media (min-width: $screen-lg-min) {
}
