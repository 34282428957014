.sect {
  padding: 3.5em 0;
  &-title {
    @include fz(25px);
    font-family: $font-family-sans-serif2;
    font-weight: 300;
    text-align: center;
    margin: 0 0 2em;
    line-height: 1.3;
    strong {
      display: block;
      @include fz(23,30);
      font-family: $font-family-sans-serif;
      font-weight: bold;
      &:before {
        content: '';
        display: block;
        margin: 0.1em auto 0.5em;
        width: 36 / 23 + 0em;
        height: 1px;
        background: $brand-primary3;
      }
    }
    &.c1 {
      color: #fff;
      strong {
        color: $brand-primary;
      }
    }
    &.c2 {
      strong {
        color: $brand-primary2;
      }
    }
  }
  &-title2 {
    color: #fff;
    @include fz(20px);
    font-weight: bold;
    text-align: center;
    line-height: 1.5;
    margin: 0 0 1.5em;
    small {
      display: block;
      color: $brand-primary;
      @include fz(20,37);
      margin-top: 0.5em;
    }
  }
}
.main {
  .banner {
    padding: 0;
    position: relative;
    .item {
      vertical-align: top;
      background: 50% 0 no-repeat;
      background-size: cover;
      height: 300px;
    }
    &-txt {
      position: absolute;
      width: 90%;
      max-width: 400px;
      right: 5%;
      bottom: percentage(443 / 914);
      transform: translateY(50%);
      background: linear-gradient(#00464e 0%, #001b1f 56.47%, #000e10 100%);
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
      padding: 34px 26px 65px;
      @include fz(18px);
      .txt1 {
        color: #fff;
        line-height: (47 / 26);
        margin-bottom: 0.5em;
      }
      .txt2 {
        color: $brand-primary;
        font-family: $font-family-sans-serif2;
        font-weight: 300;
        @include fz(50,26);
        line-height: 1.1;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: -36 * 0.5px;
        bottom: 30 * 0.5px;
        background: url(../img/banner_txt_deco.png);
        background-size: contain;
        width: 530 * 0.5px;
        height: 94 * 0.5px;
      }
      .bt {
        @include txt-hide;
        position: absolute;
        z-index: 10;
        top: 50%;
        width: 30px;
        height: 60px;
        margin-top: -30px;
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: rotate(45deg);
          width: 16px;
          height: 16px;
          margin: -8px 0 0 -8px;
          border-style: solid;
          border-color: #fff;
        }
        &.prev {
          left: -10px;
          &:before {
            border-width: 0 0 1px 1px;
          }
        }
        &.next {
          right: -10px;
          &:before {
            border-width: 1px 1px 0 0;
          }
        }
      }
    }
    // .scroll-down {
    //   position: absolute;
    //   bottom: 20px;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   padding: 0 0.25em;
    //   @include fz(46,60);
    //   animation: scrollFloating 1.2s infinite;
    //   &:hover {
    //     color: $brand-primary;
    //   }
    // }
  }
  .service {
    background: linear-gradient(#002429 0%, #000 100%);
    &-list {
      margin: 0 -13px;
      @include fz(12px);
      .box {
        position: relative;
        max-width: 400px;
        margin: 0 auto 3em;
        padding-top: 75px;
        &:after {
          content: '';
          display: block;
          width: 4.56 / 19 + 0em;
          height: 218.4 / 19 + 0em;
          background: linear-gradient(#9a373e 0%, #682e32 2.93%, #202020 89.95%, #202020 100%);
          position: absolute;
        }
        .txt {
          position: absolute;
          top: 0;
        }
        .title1 {
          color: $brand-primary;
          @include fz(65,19);
          font-family: $font-family-sans-serif2;
          font-weight: 300;
          line-height: (86 / 65);
        }
        .title2 {
          display: inline-block;
          color: #fff;
          background: $brand-primary2;
          letter-spacing: 0.2em;
          text-align: center;
          width: 204 / 19 + 0em;
          line-height: (54 / 19);
          padding: 0 1em;
        }
      }
      .item {
        position: relative;
        width: 50%;
        padding: 0 percentage(13 / 375);
        &:nth-child(2n+1) {
          clear: left;
          .txt {
            left: 0;
          }
        }
        &:nth-child(2n) {
          top: 120px;
          .txt {
            right: 0;
            text-align: right;
          }
        }
        &:nth-child(1) {
          .box {
            &:after {
              left: -13px;
              top: 70px;
            }
            .title1 {
              margin-left: -5px;
            }
            .title2 {
              margin-left: -25px;
            }
          }
        }
        &:nth-child(2) {
          .box {
            &:after {
              left: 10px;
              top: -10px;
            }
            .title2 {
              margin-right: -25px;
            }
          }
        }
        &:nth-child(3),
        &:nth-child(7) {
          .box {
            &:after {
              left: 10px;
              top: 60px;
            }
            .title2 {
              margin-left: 30px;
            }
          }
        }
        &:nth-child(4) {
          .box {
            &:after {
              right: 15px;
              bottom: -15px;
            }
            .title2 {
              margin-right: -25px;
            }
          }
        }
        &:nth-child(5) {
          .box {
            &:after {
              right: 10px;
              top: 25px;
            }
            .title1 {
              margin-left: 35px;
            }
            .title2 {
              margin-left: -25px;
            }
          }
        }
        &:nth-child(6) {
          .box {
            &:after {
              left: 10px;
              bottom: -60px;
            }
            .title1 {
              margin-right: -25px;
            }
            .title2 {
              margin-right: 30px;
            }
          }
        }
        &:nth-child(8) {
          .box {
            &:after {
              right: 15px;
              bottom: -35px;
            }
            .title1 {
              margin-right: 25px;
            }
            .title2 {
              margin-right: -25px;
            }
          }
        }
        &:nth-child(9) {
          .txt {
            left: auto;
            right: 0;
            text-align: right;
          }
          .box {
            &:after {
              left: 10px;
              bottom: -50px;
            }
            .title1 {
              margin-right: 20px;
            }
            .title2 {
              margin-right: -25px;
            }
          }
        }
      }
    }
  }
  .recommend {
    &-list {
      .box {
        max-width: 640px;
        margin: 0 auto;
        .txt {
          @include fz(16px);
          padding: 2em 1.5em;
        }
        .title1 {
          color: $brand-primary;
          @include fz(40,20);
          font-family: $font-family-sans-serif2;
          font-weight: 300;
          line-height: (55 / 40);
        }
        .title2 {
          font-weight: bold;
          line-height: 1.5;
          margin-top: 0.3em;
        }
      }
      .item {
        &:nth-child(4) {
          .box {
            .pic {
              position: relative;
              overflow: hidden;
              &:before {
                content: '';
                display: block;
                padding-top: percentage(420 / 640);
              }
              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .why {
    &-list {
      .box {
        position: relative;
        margin: 0 auto 2em;
        padding-top: 45%;
        .pic {
          position: absolute;
          top: 0;
          left: 0;
          max-width: 80%;
        }
        .txt {
          position: relative;
          background: $brand-primary2;
          box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
          padding: 2em;
          @include fz(13px);
          margin-left: 10%;
        }
        .title1 {
          color: $brand-primary3;
          @include fz(40,16);
          font-family: $font-family-sans-serif2;
          font-weight: 300;
          line-height: 1.1;
        }
        .title2 {
          color: #fff;
          @include fz(24,16);
          font-weight: bold;
          line-height: 1.3;
          margin: 0.5em 0;
        }
        .title3 {
          color: #fff;
          @include fz(20,16);
          line-height: 1.5;
        }
        .desc {
          color: $brand-primary;
          margin: 0.5em 0;
        }
        .more {
          text-align: right;
          .bt {
            display: inline-block;
          }
        }
      }
      .item {
        &:nth-child(2n) {
          .box {
            .pic {
              left: auto;
              right: 0;
            }
            .txt {
              margin-left: 0;
              margin-right: 10%;
            }
          }
        }
      }
    }
  }
  .compare {
    background: url(../img/compare_bg.jpg) 50% 0 no-repeat;
    background-size: cover;
    &-table {
      background: #fff;
      @include fz(13px);
      &-wrap {
        overflow-x: auto;
        overflow-y: hidden;
        border: 2px solid $brand-primary;
      }
      &-title {
        background: $brand-primary;
        color: #fff;
        text-align: center;
        font-weight: bold;
        padding: 0.3em 1em;
      }
      th, td {
        text-align: center;
        padding: 5px 10px;
        line-height: (25 / 18);
        &:first-child {
          border-right: 2px solid $brand-primary;
        }
      }
      th {
        white-space: nowrap;
      }
      .ic {
        display: inline-block;
        &-star {
          color: $brand-primary2;
          font-size: 1.2em;
        }
        &-circle {
          color: $brand-primary;
        }
      }
      thead {
        td {
          position: relative;
          font-weight: bold;
          .txt1 {
            text-align: left;
            margin-bottom: 1em;
          }
          .txt2 {
            text-align: right;
          }
          &:before {
            content: '';
            display: block;
            border: 1px solid $brand-primary;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 0;
            transform: skewX(-42deg);
            transform-origin: top center;
          }
        }
      }
      tbody {
        tr {
          border-top: 2px solid $brand-primary;
        }
      }
    }
    &-note {
      color: #fff;
      @include fz(13px);
      margin: 1.5em 0 0;
    }
  }
  .doctor {
    background: #1B1F22 url(../img/doctor_bg_m.jpg) 50% 100% no-repeat;
    background-size: 100% auto;
    padding-bottom: 102%;
    &-content {
      color: #fff;
      background: $brand-primary2;
      @include fz(14,16);
      padding: 2.5em 2em;
      .title {
        @include fz(29,16);
        font-weight: bold;
        line-height: 1.3;
      }
      .row {
        margin: 1em 0;
        .col {
          padding: 1em 0;
        }
        .col2 {
          border-top: 2px solid $brand-primary;
        }
      }
      .name {
        text-align: right;
        color: $brand-primary;
        font-family: Arial, sans-serif;
        font-weight: bold;
        img {
          display: inline-block;
          vertical-align: middle;
          margin-left: 1em;
          width: 120px;
        }
      }
    }
  }
  .contact {
    &-content {
      position: relative;
      background: $brand-primary2;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
      @include fz(13px);
      padding: 40px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 1px solid $brand-primary6;
        margin: 15px;
        pointer-events: none;
      }
      &:after {
        content: '';
        display: block;
        background: url(../img/contact_deco.png) no-repeat;
        background-size: contain;
        width: 321 * 0.4 + 0px;
        height: 63 * 0.4 + 0px;
        position: absolute;
        z-index: 2;
        right: -63 * 0.4 + 0px;
        bottom: 96 * 0.4 + 0px;
      }
      .pic {
        display: none;
      }
      .txt1 {
        color: #fff;
        @include fz(36,16);
        font-weight: bold;
      }
      .txt2 {
        color: $brand-primary6;
        font-family: $font-family-sans-serif2;
        font-weight: 300;
        @include fz(25,16);
        line-height: (40 / 25);
        max-width: 20em;
        margin-bottom: 1em;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .sect {
    &-title {
      @include fz(30px);
    }
    &-title2 {
      @include fz(30px);
    }
  }
  .main {
    .banner {
      &-txt {
        .bt {
          &.prev{
            left: -30px;
          }
          &.next {
            right: -30px;
          }
          &:hover {
            &:before {
              border-color: $brand-primary3;
            }
          }
        }
      }
    }
    .service {
      .container {
        max-width: 1150px;
      }
      &-list {
        margin: 0;
        @include fz(16px);
        .box {
          padding-top: 125 / 19 + 0em;
          margin-bottom: 132 / 19 + 0em;
          .title2 {
            transition: 0.3s;
          }
        }
        .box:hover {
          .title2 {
            background: $brand-primary;
          }
        }
        .item {
          padding: 0 5%;
          &:nth-child(2n) {
            top: 178 / 19 + 0em;
          }
          &:nth-child(1) {
            .box {
              &:after {
                left: -45 / 19 + 0em;
                top: 124 / 19 + 0em;
              }
              .title1 {
                margin-left: -25 / 65 + 0em;
              }
              .title2 {
                margin-left: -77 / 19 + 0em;
              }
            }
          }
          &:nth-child(2) {
            .box {
              &:after {
                left: 21 / 19 + 0em;
                top: -25 / 19 + 0em;
              }
              .title2 {
                margin-right: -77 / 19 + 0em;
              }
            }
          }
          &:nth-child(3),
          &:nth-child(7) {
            .box {
              &:after {
                left: 42 / 19 + 0em;
                top: 95 / 19 + 0em;
              }
              .title2 {
                margin-left: 125 / 19 + 0em;
              }
            }
          }
          &:nth-child(4) {
            .box {
              &:after {
                right: 42 / 19 + 0em;
                bottom: -46 / 19 + 0em;
              }
              .title2 {
                margin-right: -77 / 19 + 0em;
              }
            }
          }
          &:nth-child(5) {
            .box {
              &:after {
                right: 48 / 19 + 0em;
                top: 32 / 19 + 0em;
              }
              .title1 {
                margin-left: 126 / 65 + 0em;
              }
              .title2 {
                margin-left: -77 / 19 + 0em;
              }
            }
          }
          &:nth-child(6) {
            .box {
              &:after {
                left: 21 / 19 + 0em;
                bottom: -157 / 19 + 0em;
              }
              .title1 {
                margin-left: -160 / 65 + 0em;
              }
              .title2 {
                margin-right: 97 / 19 + 0em;
              }
            }
          }
          &:nth-child(8) {
            .box {
              &:after {
                right: 42 / 19 + 0em;
                bottom: -46 / 19 + 0em;
              }
              .title1 {
                margin-left: 80 / 65 + 0em;
              }
              .title2 {
                margin-right: -77 / 19 + 0em;
              }
            }
          }
          &:nth-child(9) {
            .box {
              &:after {
                left: 42 / 19 + 0em;
                bottom: -110 / 19 + 0em;
              }
              .title1 {
                margin-left: 42 / 65 + 0em;
              }
              .title2 {
                margin-right: -77 / 19 + 0em;
              }
            }
          }
        }
      }
    }
    .recommend {
      .container {
        padding: 0;
        max-width: none;
      }
      &-list {
        .box {
          width: 100%;
          max-width: none;
          display: flex;
          align-items: stretch;
          position: relative;
          &:after {
            content: '';
            display: block;
            width: 120px;
            height: 3px;
            background: $brand-primary3;
            margin-left: -30px;
            position: absolute;
            left: 50%;
            bottom: percentage(70 / 420);
          }
          .pic,
          .txt {
            width: 50%;
          }
          .txt {
            padding: percentage(70 / 1280) percentage(70 / 1280) percentage(90 / 1280);
          }
        }
        .box:hover {
          .title1 {
            color: $text-color;
          }
          .title2 {
            color: $brand-primary;
          }
        }
        .item {
          &:nth-child(2n+1) {
            .box {
            }
          }
          &:nth-child(2n) {
            .box {
              flex-direction: row-reverse;
              &:after {
                margin-left: -90px;
              }
            }
          }
        }
      }
    }
    .why {
      &-list {
        .box {
          padding: 0;
          margin-bottom: 4em;
          .pic {
            width: percentage(600 / 1118);
          }
          .txt {
            padding: percentage(50 / 1118) percentage(60 / 1118);
          }
        }
        .item {
          &:nth-child(2n+1) {
            .box {
              padding-top: percentage(32 / 1118);
              .txt {
                margin-left: percentage(446 / 1118);
              }
            }
          }
          &:nth-child(2n) {
            .box {
              padding-bottom: percentage(32 / 1118);
              .pic {
                margin-top: percentage(32 / 1118);
              }
              .txt {
                margin-right: percentage(446 / 1118);
              }
            }
          }
        }
      }
    }
    .doctor {
      background: #1B1F22 url(../img/doctor_bg.jpg) 25% 0 no-repeat;
      padding: 3em 0;
      &-content {
        margin-left: 45%;
      }
    }
    .compare {
      padding: 5em 0;
      .container {
        width: 92%;
      }
    }
    .contact {
      padding-top: 10%;
      .container {
        padding-bottom: 87px;
        &:before {
          content: '';
          display: block;
          width: 5px;
          height: 141px;
          background: linear-gradient(#9a373e 0%, #682e32 2.9%, #202020 90%, #202020 100%);
          position: absolute;
          z-index: 10;
          left: 50%;
          bottom: 0;
          margin-left: -2.5px;
        }
      }
      &-content {
        padding: 60px percentage(160 / 1290) 65px percentage(540 / 1290);
        &:after {
          width: 321 * 0.65 + 0px;
          height: 63 * 0.65 + 0px;
          right: -63 * 0.65 + 0px;
          bottom: 96 * 0.65 + 0px;
        }
        .pic {
          display: block;
          position: absolute;
          bottom: -40px;
          left: percentage(80 / 1180);
          z-index: 3;
          width: percentage(395 / 1180);
        }
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .sect {
    padding-top: 6em;
    &-title {
      margin-bottom: 2.8em;
    }
  }
  .main {
    .banner {
      &-txt {
        @include fz(21px);
        max-width: 500px;
        padding: 45px 30px 95px;
        right: percentage(134 / 1920);
        &:after {
          left: -36 * 0.75px;
          bottom: 30 * 0.75px;
          width: 530 * 0.75px;
          height: 94 * 0.75px;
        }
        .bt {
          width: 45px;
          height: 90px;
          margin-top: -45px;
          &:before {
            width: 30px;
            height: 30px;
            margin: -15px 0 0 -15px;
          }
          &.prev {
            left: -60px;
          }
          &.next {
            right: -60px;
          }
        }
      }
    }
    .service {
      &-list {
        @include fz(19px);
      }
    }
    .recommend {
      &-list {
        position: relative;
        .item {
          width: 66.66%;
          &:nth-child(4) {
            width: 33.34%;
            position: absolute;
            right: 0;
            top: 0;
            .box {
              display: block;
              &:after {
                width: 3px;
                height: 120px;
                margin: -30px 0 0;
                top: 66.66%;
                left: percentage(524 / 640);
                bottom: auto;
              }
              .pic,
              .txt {
                width: auto;
              }
              .pic {
                &:before {
                  display: none;
                }
                img {
                  position: static;
                }
              }
              .txt {
                padding: 0;
                position: relative;
                background: $brand-primary2;
                &:before {
                  content: '';
                  display: block;
                  padding-top: percentage(420 / 640);
                }
                .inner {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  padding: percentage(140 / 1280) percentage(140 / 1280) percentage(180 / 1280);
                }
                .title2 {
                  color: #fff;
                }
              }
            }
            .box:hover {
              .title1 {
                color: #fff;
              }
              .title2 {
                color: $brand-primary;
              }
            }
          }
        }
      }
    }
    .why {
      &-list {
        max-width: 1118px;
        margin: 0 auto;
        .box {
          .txt {
            @include fz(16px);
          }
        }
      }
    }
    .compare {
      &-table {
        &-title {
          @include fz(18px);
        }
        @include fz(16px);
        thead {
          td {
            width: 9%;
          }
        }
      }
      &-note {
        @include fz(14px);
      }
    }
    .contact {
      padding-top: 13%;
      .container {
        width: 90%;
      }
      &-content {
        padding-top: 110 / 16 + 0em;
        padding-bottom: 115 / 16 + 0em;
        &:before {
          margin: 25px;
        }
      }
    }
  }
}
@media (min-width: $screen-lg-min) {
  .sect {
    &-title2 {
      @include fz(37px);
    }
  }
  .main {
    .compare {
      padding: percentage(90 / 1920) 0 percentage(165 / 1920);
      &-table {
        &-title {
          @include fz(20px);
        }
        @include fz(18px);
      }
      &-note {
        @include fz(16px);
      }
    }
    .doctor {
      padding: 10% 0 9%;
      .container {
        max-width: 1670px;
        width: 96%;
      }
      &-content {
        padding: percentage(50 / 1620) percentage(60 / 1620);
        .title {
          margin-bottom: 1.4em;
        }
        .row {
          @include clearfix;
          margin: 0 percentage(-50 / 750);
          position: relative;
          &:before {
            content: '';
            display: block;
            border-left: 2px solid $brand-primary;
            position: absolute;
            top: 0;
            bottom: 0;
            left: percentage(360 / 850);
          }
          .col {
            float: left;
            padding: 0 percentage(50 / 850);
          }
          .col1 {
            width: percentage(360 / 850);
          }
          .col2 {
            border-top: 0;
            width: percentage(490 / 850);
          }
        }
        .name {
          padding-top: 1.5em;
          margin-right: 5%;
          img {
            width: auto;
          }
        }
      }
    }
    .contact {
      &-content {
        @include fz(16px);
        &:before {
          margin: 30px;
        }
        &:after {
          width: 321px;
          height: 63px;
          right: -63px;
          bottom: 96px;
        }
      }
    }
  }
}
@media (min-width: $screen-xl-min) {
  .main {
    .banner {
      &-txt {
        @include fz(26px);
        max-width: 615px;
        padding: 60px 54px 130px;
        &:after {
          left: -36px;
          bottom: 30px;
          width: 530px;
          height: 94px;
        }
        .bt {
          &.prev {
            left: -80px;
          }
          &.next {
            right: -80px;
          }
        }
      }
    }
    .recommend {
      &-list {
        .box {
          .txt {
            @include fz(20px);
          }
        }
      }
    }
    .doctor {
      background-position: 50% 0;
      &-content {
        margin-left: percentage(750 / 1620);
      }
    }
  }
}
