.wrapper {
  padding: 60px 0 50px;
}
.container {
  margin: 0 auto;
  padding: 0 25px;
  max-width: 1230px;
}
.main {
  position: relative;
}

@media (min-width: $screen-sm-min) {
  .wrapper {
    padding-bottom: 0;
  }
  .main {
  }
}
@media (min-width: $screen-md-min) {
  .wrapper {
    padding-top: 78px;
  }
  .main {
  }
}
@media (min-width: $screen-lg-min) {
}
